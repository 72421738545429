@import '../../../scss/base.module';

.spotty-cat-vp-modal {
  padding: $spacing-m;
  box-sizing: border-box;

  &__title {
    @include font-title;
    @include font-xl;

    width: 100%;
    display: block;
    text-align: center;
  }

  &__description {
    @include font-s;

    text-align: center;
    color: $medium;
    margin: $spacing-l $spacing-m;
  }

  &__image {
    width: 160px;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  &__button {
    display: block;
    margin: $spacing-l auto $spacing-xl;
  }
}
